import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./global.scss";

const Header = ({ siteTitle }) => (
  <header>
    <div className="header-inner">
      <div className="title">
        <Link to="/">
          {siteTitle}
        </Link>
      </div>
      <Link className="quicklink" to="/formulaire">formulaire de téléconsultation</Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

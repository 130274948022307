/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
      </div>
      <footer>
        <div className="footer-inner">
          <div className="footer-description">
            <span><a href="https://www.docvadis.fr/cadoret-philippe-dermatologue" target="_blank">Docteur Philippe Cadoret</a></span>{<br />}
            <span>Dermatologue</span>{<br />}
          </div>
          <div className="footer-address">
            <div><span>2 Rue Alexandre Le Pontois{<br />}56000 Vannes</span></div>
            <div className="footer-tel">
              <span>Fixe : 02 97 47 66 14</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

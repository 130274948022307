import PropTypes from "prop-types"
import React, { Component, useState } from 'react'

import { Link } from "gatsby"
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';

import Layout from "../components/layout"
import SEO from "../components/seo"

//import HomeData from "../../content/pages/home.json"

import { ProfilePic } from "../components/imgs/profilepic"


export default class IndexPage extends Component {
	  constructor(props) {
    super(props);
    this.layout = React.createRef();
  }

  componentDidMount() {
  	//console.log(HomeData)
    this.setState({activeIndex: 1});
  }

  faqClick = (elem) => {
    //console.log(elem);
    this.setState({activeIndex: elem});
  }

  render() {
  	return (
  	  <Layout>
		    <SEO title="La télémédecine en dermatologie" description="Le docteur en dermatologie Philippe Cadoret (exerçant à Vannes, morbihan) explique comment la télémedecine et la téléconsultation fonctionne"/>

        <div className="container intro"> 
  		    <h1>La télémédecine en dermatologie</h1>
          <div className="profile-pic">
            <ProfilePic />
          </div>
  		    <h2>Certaines consultations dermatologiques peuvent s’effectuer par téléconsultation.</h2>
          <p>La téléconsultation en dermatologie concerne certaines pathologies chroniques et suivis de traitements (ex : acné, psoriasis, eczéma…). </p>
          <p>La téléconsultation en dermatologie exclut toute pathologie tumorale sauf si la lésion que vous présentez est récente et vous inquiète. Un avis consultatif peut être donné dans les limites de la qualité de l’image reçue.</p>
          <p>La téléconsultation de contrôle des grains de beauté est bien entendu totalement exclue sauf si vous avez un doute sur un grain de beauté qui se modifie.</p>
          <p>Les avantages de la téléconsultation en dermatologie sont très nombreux :</p>
          <ul>
            <li>Lutte contre les déserts médicaux en permettant à des personnes isolées de consulter un médecin</li>
            <li>Réduction des délais pour avoir un rendez-vous</li>
            <li>Accès facilité à un spécialiste de la peau</li>
            <li>Réductions des déplacements des patients et des professionnels (en particulier pour les personnes âgées ou à mobilité réduite)</li>
            <li>Amélioration du suivi de pathologies chroniques</li>
          </ul>
          <p>La téléconsultation en dermatologie a lieu par visioconférence « face to face », avec envoi rapide d’ordonnance(s) sécurisée(s) si nécessaire, de lettre(s) adressée(s) au médecin traitant ou à un autre confrère (chirurgien par exemple).</p>
          <p>Vous émettez le désir d’avoir une téléconsultation dermatologique avec le Docteur CADORET : votre prochain rendez-vous, s’il correspond aux critères d’éligibilité d’une téléconsultation, peut avoir lieu de cette manière.</p>
          <p>&nbsp;</p>
        </div>
        <div className="container faq">
          <h2>Questions / Réponses</h2>

          <Accordion atomic={true}>
            <AccordionItem title="Comment s'équiper pour la téléconsultation ?">
              <div className="acc-item-inner">
                <p>Pour une téléconsultation de qualité optimale, vous devez avoir :</p>
                <ul>
                  <li>Un smartphone ou une tablette muni d’une caméra de qualité correcte.</li>
                  <li>Une bonne connexion internet WIFI pour bénéficier d’un échange de qualité suffisante. Pratiquer une téléconsultation à partir d’un réseau internet téléphonique ne suffit pas pour garantir un bon échange.</li>
                </ul>
                <p>La téléconsultation aura ensuite lieu directement par les moyens de Medaviz. Nul besoin donc de s'équiper de Skype ou Facetime.</p>
                <p><a href="https://stonly.com/explanation/medaviz-mode-demploi-patient-avenant-6-O5LOqEnZM2/Steps/12605" target="_blank">Plus d'infos sur Medaviz</a></p>
                <p>&nbsp;</p>
                <p className="bolded">Important: Sécurité des données </p>
                <p className="footnote">Parce que la sécurité des données de santé est fondamentale, Medaviz garantit la sécurisation de l'ensemble des flux.{<br />}
                Les données de santé sont hébergées chez un hébergeur agréé de données de santé (HDS), qui
                répond à des normes réglementaires très strictes.{<br />}
                Medaviz est en conformité avec la réglementation française en vigueur ainsi qu'avec les
                recommandations de la HAS et la CNIL. Les données médicales sont stockées pendant la durée de
                conservation légale et ne sont jamais exploitées à des fins commerciales.{<br />}
                <a href="https://www.coreye.fr/fr">Plus d'infos sur notre hébergeur</a> </p>
              </div>

            </AccordionItem>

            <AccordionItem title="Comment prendre rendez-vous en téléconsultation ?">
              <div className="acc-item-inner">
                <ul>
                  <li>Pour accéder à une téléconsultation avec le Docteur CADORET, vous devrez communiquer par mail : vos nom et prénom, votre numéro de téléphone mobile, votre adresse mail via <Link to="/formulaire">ce formulaire.</Link></li>
                  <li>Vous recevrez, de la part du Docteur CADORET, un SMS avec un lien vous invitant à télécharger l’application Medaviz ainsi que le mot de passe qui vous permettra de vous connecter. Ce mot de passe vous sera personnel. Medaviz est accessible depuis l’application disponible sur l’App store (Iphones et tablettes Apple) et le Play store (téléphones et tablettes Androïd).{<br />}
                    <span className="bolded red">Attention +++ </span>: ne pas télécharger l’application Medaviz tant que vous n’avez pas reçu par SMS l'invitation et le mot de passe sinon le système sera bloqué.</li>
                  <li>Une fois l’application chargée dans votre téléphone, vous serez invité à communiquer vos coordonnées bancaires pour les futurs règlements. Il s’agit d’un site sécurisé et vous n’avez aucune crainte à avoir sur la confidentialité de vos données. Cette garantie est assurée.</li>
                  <li>Une téléconsultation vous sera ensuite proposée et planifiée, vous serez informé par mail et sur l’application Medaviz du jour et de l’heure de ce rendez-vous, en téléconsultation.</li>            
                </ul>
              </div>
            </AccordionItem>

            <AccordionItem title="Que faire le jour de mon RDV ?">
              <div className="acc-item-inner">
                <p>Quelques rappels de base :</p>
                <ul>
                  <li>Veillez à ce que votre smartphone ou votre tablette ait la batterie suffisamment chargée pour tenir tout au long de la téléconsultation.</li>
                  <li>Pour une meilleure qualité de son et d’audio, il est recommandé d'utiliser un casque avec micro.</li>
                  <li>Positionnez votre mobile face à vous, si possible contre un support stable, pour garantir la meilleure qualité de la consultation.</li>
                  <li>Soyez ponctuel.</li>
                  <li>Veillez à ce que la consultation vidéo se tienne dans un endroit calme et isolé de votre domicile ou à votre travail permettant ainsi de respecter la confidentialité de la consultation.</li>
                  <li>Faites attention aux reflets de l’écran (ou autres sources de lumières) sur vos lunettes et au contre-jour.</li>
                </ul>
              </div>
            </AccordionItem>

            <AccordionItem title="Comment se déroule la téléconsultation ?">
              <div className="acc-item-inner">
                <ul>
                  <li>5 minutes avant l’heure fixée du rendez-vous, vous devrez vous connecter sur l’application Medaviz.</li>
                  <li>A l’heure du rendez-vous : vous recevrez un appel via l'application Medaviz, et la consultation (visioconference) débutera.</li>
                  <li>A noter : le praticien peut avoir du retard. Restez connecté sur l'application. En cas de retard du praticien, il a la possibilité de vous prévenir par SMS du temps de retard estimé.</li>
                  <li>Lors de cette consultation, le Docteur CADORET pourra vous interroger, vous examiner, des documents pourront être échangés, une (des) ordonnance(s) sécurisée(s) vous sera (seront) transmise(s), ainsi que des documents que vous recevrez par mail avec la feuille de maladie pour votre remboursement. Il est possible également que le praticien télétransmette directement à la caisse de sécurité sociale ou toute autre caisse.</li>
                </ul>
              </div>
            </AccordionItem>

            <AccordionItem title="Combien coûte une téléconsultation ?">
              <div className="acc-item-inner">
                <p>Le même prix qu'une consultation traditionnelle chez un spécialiste : 30 € ou 50 € pour les médecins spécialistes, de secteur 1, adhérant au dispositif de pratique tarifaire maîtrisée.</p>
              </div>
            </AccordionItem>

            <AccordionItem title="Est-ce que cette téléconsultation est remboursée ?">
              <div className="acc-item-inner">
                <p>Depuis le 18 mars, la téléconsultation est prise en charge à 100 % par l’Assurance maladie obligatoire.</p>
                <p>Cependant, s’il s’agit d’une téléconsultation à caractère purement esthétique, celle-ci ne sera pas remboursable.</p>
              </div>
            </AccordionItem>

            <AccordionItem title="Que faire si je n'ai pas de médecin traitant ?">
              <div className="acc-item-inner">
                <p>Aucun problème, <a href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000041704122&amp;categorieLien=id" target="_blank">un décret</a> du 10 mars, et valable jusqu'au 30 avril 2020, assouplit les règles de téléconsultation : "L’obligation de passer par son médecin traitant est levée. Chaque patient pourra bénéficier d’une téléconsultation si nécessaire", précise le gouvernement.</p>
              </div>
            </AccordionItem>
          </Accordion>
        </div>
		  </Layout>
	  )
  }
}
